<template>
  <v-container class="px-8 py-0 fill-height" fluid>
    <!--  Navigation  -->
    <u-top-nav />
    <v-row align="stretch" class="fill-height">
      <v-col class="my-2" cols="12" md="9">
        <!--  Project Carousel  -->
        <project-carousel />

        <!--  Templates  -->
        <v-row class="mx-12 my-2" justify="space-between">
          <h1>Templates</h1>
          <u-button
            class="text-capitalize font-weight-bold secondary--text"
            color="gray-7"
          >
            View all
          </u-button>
        </v-row>
        <v-slide-group show-arrows="always">
          <v-slide-item
            v-for="template in templates"
            :key="template.template_id"
            class="mx-1"
          >
            <u-template-tile
              :label="template.template_name"
              icon="fas fa-car"
              @click.native="onClickTemplateTile(template)"
            />
          </v-slide-item>
        </v-slide-group>
        <v-row class="mx-md-12 my-2">
          <u-button
            block
            class="text-none font-weight-bold space-mono"
            color="gray-7"
            large
            @click="onClickTemplateTile({})"
          >
            <v-icon class="mr-8" small>fas fa-plus</v-icon>
            Start a blank project
          </u-button>
        </v-row>
      </v-col>

      <!--  New Features & Quick Actions  -->
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        class="background px-6 d-flex flex-column justify-space-around"
        cols="12"
        md="3"
      >
        <new-feature-card
          :source="require('../assets/primary-pattern.svg')"
          alt_source=""
          lazy_source=""
        />
        <dashboard-widget-container />
      </v-col>
      <v-col v-else class="background">
        <v-row align="stretch">
          <v-col align-self="center">
            <new-feature-card
              :source="require('../assets/primary-pattern.svg')"
              alt_source=""
              lazy_source=""
            />
          </v-col>
          <v-col align-self="center">
            <dashboard-widget-container />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { UButton } from "@/components/base";
import { UTemplateTile, UTopNav } from "@/components/common";
import {
  DashboardWidgetContainer,
  NewFeatureCard,
  ProjectCarousel,
} from "@/components";
import { mapState } from "vuex";

export default {
  name: "Dashboard",
  components: {
    UButton,
    DashboardWidgetContainer,
    NewFeatureCard,
    ProjectCarousel,
    UTopNav,
    UTemplateTile,
  },
  computed: {
    ...mapState(["templates"]),
  },
  mounted() {
    this.$store.dispatch("User/getProjects");
    this.$store.dispatch("getTemplates");
  },
  methods: {
    // Store the Template object and navigate to create-project screen
    onClickTemplateTile(template = {}) {
      this.$store.commit("User/SET_SELECTED_TEMPLATE", template);
      this.$router.push({ name: "Create Project" });
    },
  },
};
</script>
