import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"px-8 py-0 fill-height",attrs:{"fluid":""}},[_c('u-top-nav'),_c(VRow,{staticClass:"fill-height",attrs:{"align":"stretch"}},[_c(VCol,{staticClass:"my-2",attrs:{"cols":"12","md":"9"}},[_c('project-carousel'),_c(VRow,{staticClass:"mx-12 my-2",attrs:{"justify":"space-between"}},[_c('h1',[_vm._v("Templates")]),_c('u-button',{staticClass:"text-capitalize font-weight-bold secondary--text",attrs:{"color":"gray-7"}},[_vm._v(" View all ")])],1),_c(VSlideGroup,{attrs:{"show-arrows":"always"}},_vm._l((_vm.templates),function(template){return _c(VSlideItem,{key:template.template_id,staticClass:"mx-1"},[_c('u-template-tile',{attrs:{"label":template.template_name,"icon":"fas fa-car"},nativeOn:{"click":function($event){return _vm.onClickTemplateTile(template)}}})],1)}),1),_c(VRow,{staticClass:"mx-md-12 my-2"},[_c('u-button',{staticClass:"text-none font-weight-bold space-mono",attrs:{"block":"","color":"gray-7","large":""},on:{"click":function($event){return _vm.onClickTemplateTile({})}}},[_c(VIcon,{staticClass:"mr-8",attrs:{"small":""}},[_vm._v("fas fa-plus")]),_vm._v(" Start a blank project ")],1)],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c(VCol,{staticClass:"background px-6 d-flex flex-column justify-space-around",attrs:{"cols":"12","md":"3"}},[_c('new-feature-card',{attrs:{"source":require('../assets/primary-pattern.svg'),"alt_source":"","lazy_source":""}}),_c('dashboard-widget-container')],1):_c(VCol,{staticClass:"background"},[_c(VRow,{attrs:{"align":"stretch"}},[_c(VCol,{attrs:{"align-self":"center"}},[_c('new-feature-card',{attrs:{"source":require('../assets/primary-pattern.svg'),"alt_source":"","lazy_source":""}})],1),_c(VCol,{attrs:{"align-self":"center"}},[_c('dashboard-widget-container')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }